<template>
    <v-content>
        <div>
            <section class="hero css-hero" :style="{ backgroundImage: 'url(' + require('@/assets/spacecraft.jpg') + ')' }">
                <div class="columns">
                    <div class="column is-4">
                        <br /><br />
                        <div class="box" style="margin-left: 5%">
                            <h1 class="title">{{ workshop.name }}</h1>
                            <h2 class="subtitle">{{ workshop.blurb }}</h2>
                            <img :src="workshop.hero" />
                            <h2 class="subtitle">Hosted {{ workshop.host }}</h2>
                            <h3 class="subtitle2">
                                <vue-markdown>{{ workshop.location }}</vue-markdown>
                            </h3>
                            <h3 class="subtitle2">{{ workshop.date_range }}</h3>
                            <div class="field has-addons" style="display: flex; flex-wrap: wrap; gap: 10px">
                                <p class="control">
                                    <a
                                        class="button is-large is-warning"
                                        :href="workshop.tickets"
                                        v-if="workshop.open && workshop.free != true && workshop.live == false"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span class="icon is-small">
                                            <i class="fas fa-ticket-alt"></i>
                                        </span>
                                        <span>Register for {{ format_workshop_id(workshop.id) }} here!</span>
                                    </a>
                                </p>
                                <p class="control">
                                    <a class="button is-large is-success" :href="workshop.tickets" v-if="workshop.open && workshop.free">
                                        <span class="icon is-small">
                                            <i class="fas fa-ticket-alt"></i>
                                        </span>
                                        <span>Tickets!</span>
                                    </a>
                                </p>
                                <!-- <p class="control">
                            <a class="button is-large is-danger" :href="workshop.tickets" v-if="workshop.open != true && workshop.latest != true">
                                <span class="icon is-small">
                                    <i class="fas fa-ticket-alt"></i>
                                </span>
                                <span>Sale Closed</span>
                            </a>
                        </p> -->
                                <p class="control">
                                    <a class="button is-large is-danger" :href="workshop.youtube_playlist" v-if="workshop.youtube_playlist != None">
                                        <span class="icon is-small">
                                            <i class="fab fa-youtube"></i>
                                        </span>
                                        <span>YouTube Playlist</span>
                                    </a>
                                </p>
                                <p class="control">
                                    <a class="button is-large is-info" :href="workshop.subscribe" v-if="workshop.subscribe != None">
                                        <span class="icon is-small">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                        <span>Subscribe</span>
                                    </a>
                                </p>
                                <p class="control">
                                    <a class="button is-large is-danger" to="#" v-scroll-to="'#LIVESchedule'" v-if="workshop.open && workshop.live == true">
                                        <span class="icon is-small">
                                            <i class="fas fa-rocket blink" style="color: white"></i>
                                        </span>
                                        <span>Live!</span>
                                    </a>
                                </p>
                                <p class="control">
                                    <a class="button is-large is-primary" :href="workshop.slack" v-if="workshop.slack">
                                        <span class="icon is-small">
                                            <i class="fab fa-slack"></i>
                                        </span>
                                        <span>Slack</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </div>
            </section>
            <div class="PageSections">
                <div class="card">
                    <div class="card-content">
                        Table of Contents: <br />
                        <router-link to="#" v-scroll-to="'#Overview'"> Overview </router-link>
                        <router-link v-if="workshop.keynotes" to="#" v-scroll-to="'#Keynotes'">
                            <br />
                            Keynotes
                        </router-link>
                        <router-link v-if="workshop.townhalls || workshop.pres_slides_link" to="#" v-scroll-to="'#Townhalls'">
                            <br />
                            Town Halls
                        </router-link>
                        <router-link v-if="workshop.live_schedule != None && workshop.live == false" to="#" v-scroll-to="'#Schedule'">
                            <br />
                            Schedule
                        </router-link>
                        <router-link v-if="workshop.presentations || workshop.pres_slides_link" to="#" v-scroll-to="'#Presentation_Slides'">
                            <br />
                            Presentation Slides
                        </router-link>
                        <router-link v-if="workshop.attendee_info != None" to="#" v-scroll-to="'#WorkshopLogistics'">
                            <br />
                            Logistics
                        </router-link>
                        <router-link
                            v-if="workshop.attendee_info != None && workshop.attendee_info.attendee != None"
                            to="#"
                            v-scroll-to="'#AttendeeInstructions'"
                        >
                            <br />
                            Instructions
                        </router-link>
                        <router-link v-if="workshop.code_of_conduct != None" to="#" v-scroll-to="'#COC'">
                            <br />
                            Code of Conduct
                        </router-link>
                        <router-link v-if="workshop.attendee_info != None && workshop.attendee_info.faqs != None" to="#" v-scroll-to="'#FAQs'">
                            <br />
                            FAQs
                        </router-link>
                        <router-link v-if="workshop.attendee_info != None && workshop.attendee_info.hotel != None" to="#" v-scroll-to="'#Hotels'">
                            <br />
                            Hotel
                        </router-link>
                        <router-link v-if="workshop.attendee_info != None && workshop.attendee_info.abstracts != None" to="#" v-scroll-to="'#Abstracts'">
                            <br />
                            Call for Abstracts
                        </router-link>
                        <router-link v-if="workshop.committee" to="#" v-scroll-to="'#Coordinators'">
                            <br />
                            Coordinators
                        </router-link>
                        <router-link v-if="workshop.sponsors" to="#" v-scroll-to="'#Sponsors'">
                            <br />
                            Sponsors
                        </router-link>
                    </div>
                </div>
            </div>
            <section v-if="workshop.live_schedule != None && workshop.latest == true && workshop.live == true">
                <div class="container">
                    <div>
                        <br />
                        <h1 class="title">The FSW Workshop is Happening NOW!</h1>
                        <h2 class="subtitle">To join us check your e-mail for the daily ZOOM passwords to the Conference Talks and Sponsor Kiosks!</h2>
                        <div class="card">
                            <div class="card-content">
                                <SectionOne id="LIVESchedule" />
                                <h1 class="title">Live Schedule</h1>
                                <vue-markdown v-if="workshop.schedule_info != None">{{ workshop.schedule_info }}</vue-markdown>
                                <iframe :src="workshop.live_schedule" style="width: 100%; height: 780px; border: 0"></iframe>
                                <br />
                                <a :href="workshop.schedule_download" style="width: 100%; height: 600px; border: 0">Download a Live Schedule PDF version</a>
                                <br />
                                <p v-if="workshop.static_schedule != None">
                                    If you are having issues seeing the most recent version of the schedule (updated live every 5 minutes), feel free to
                                    download the latest static version.
                                    <a :href="workshop.static_schedule" download style="width: 100%; height: 600px; border: 0">Download Static Schedule</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="columns">
                        <div class="column" v-if="workshop.sel_abstracts_link != None">
                            <div class="card" v-if="workshop.latest == true">
                                <div class="card-content"><h1 class="title">This Year's Abstracts</h1></div>
                                <div class="card-content">
                                    <iframe :src="workshop.sel_abstracts_link" style="width: 90%; height: 400px; border: 0"></iframe>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="right-large" v-if="workshop.attendee_info.code_of_conduct != None">
                        <br/>
                        <SectionOne id="COC"/>
                        <div v-for="(coc, i) in workshop.attendee_info.code_of_conduct" :key="i">
                            <vue-markdown>{{coc.p}}</vue-markdown>
                            <iframe v-if="coc.link" :src="coc.link" style="width:100%; height:300px; border:0;"></iframe>
                        </div>
                    </div> -->
                        <div class="column" v-if="workshop.pres_slides_link != None">
                            <SectionOne id="pres_slides_link" />
                            <div class="card" v-if="workshop.latest == true">
                                <div class="card-content">
                                    <h1 class="title">LIVE Presentation Slides</h1>
                                    <p>These are uploaded daily at the conclusion of each FSW Workshop day</p>
                                </div>
                                <div class="card-content"><iframe :src="workshop.pres_slides_link" style="width: 100%; height: 400px; border: 0"></iframe></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="section">
                <div class="container">
                    <div class="columns">
                        <div class="left" id="large-screen-column">
                            <div class="column">
                                <SectionOne id="Overview" />
                                <h1 class="title">Overview</h1>
                                <div v-for="(desid, i) in workshop.description" :key="i" class="workshop-description">
                                    <vue-markdown>{{ desid }}</vue-markdown>
                                </div>
                                <div class="container" v-if="workshop.old_site">
                                    You can access the {{ workshop.name }}'s content in our <a :href="workshop.old_site_link">archived site.</a>
                                </div>
                            </div>
                        </div>
                        <div class="right" id="large-screen-column">
                            <div class="column">
                                <div v-if="workshop.timeline != None">
                                    <h1 class="title">Important Dates</h1>
                                    <div class="timeline">
                                        <header class="timeline-header">
                                            <span class="tag is-medium is-info">Start of Workshop Cycle</span>
                                        </header>
                                        <div v-for="(timeid, i) in workshop.timeline" :key="i">
                                            <div class="timeline-item">
                                                <div class="timeline-marker is-icon">
                                                    <i class="fas fa-long-arrow-alt-down"></i>
                                                </div>
                                                <div class="timeline-content">
                                                    <p class="heading">{{ timeid.date }}</p>
                                                    <p>
                                                        <span v-html="timeid.name"></span>
                                                        <i class="fas fa-check" style="color: green; padding-left: 0.25em" v-if="timeid.done"></i>
                                                    </p>
                                                    <p>{{ timeid.note }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="timeline-header">
                                            <span class="tag is-medium is-info">End of Workshop Cycle</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" v-if="workshop.keynotes">
                <div class="container">
                    <SectionOne id="Keynotes" />
                    <h1 class="title"><b>Keynotes</b></h1>
                    <div class="columns is-multiline is-centered" align="center">
                        <div class="column is-2" v-for="(keynote, i) in workshop.keynotes" :key="i">
                            <div class="card has-equal-height" v-on:click="set_keynote(keynote.name)" type="button" style="cursor: pointer">
                                <div class="card-image">
                                    <img width="100%" height="100" :src="keynote.image" />
                                </div>
                                <div class="card-content">
                                    <div class="content" align="center">
                                        <b>{{ keynote.name }}</b
                                        ><br />
                                        <b style="color: gray">{{ keynote.position }}</b
                                        ><br />
                                        <i>{{ keynote.institution }}</i
                                        ><br />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <card-modal :visible="spanVisible" @ok="close" @cancel="close" v-if="spanVisible == keynote.name">
                                    <div class="content has-text-centered">
                                        <img width="65%" border-radius="10%" :src="keynote.image" /><br />
                                        <b>{{ keynote.name }}</b
                                        ><br />
                                        <b style="color: gray">{{ keynote.position }}</b
                                        ><br />
                                        <i>{{ keynote.institution }}</i
                                        ><br />
                                        <span v-if="keynote.talk_title"
                                            ><b>Talk: "{{ keynote.talk_title }}" </b><br
                                        /></span>
                                        <br />
                                        <p>
                                            <vue-markdown>{{ keynote.bio }}</vue-markdown>
                                        </p>
                                        <br />
                                    </div>
                                </card-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" id="townhalls" v-if="workshop.townhalls">
                <div class="container">
                    <SectionOne id="Townhalls" />
                    <h1 class="title"><b>Town Halls</b></h1>

                    <div class="tile is-ancestor">
                        <div class="tile is-parent" v-for="(townhall, i) in workshop.townhalls" :key="i">
                            <article class="tile is-child notification is-info is-light">
                                <div class="content">
                                    <p class="title is-4">{{ townhall.name }}</p>
                                    <p class="subtitle is-5 has-text-grey">Moderator</p>
                                    <div class="content townhall-participants">
                                        <div>
                                            <figure class="image is-128x128 is-inline-block">
                                                <img class="is-square" :src="townhall.moderator.image" />
                                            </figure>
                                        </div>
                                        <div>
                                            <span class="has-text-weight-bold" v-text="townhall.moderator.name"></span>
                                            <div v-for="(role, i) in townhall.moderator.roles" :key="i" style="margin-bottom: 10px">
                                                <span v-html="role.title"></span>
                                                <span class="is-italic" v-text="role.institution"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="subtitle is-5 has-text-grey">Panelists</p>
                                    <div class="content townhall-participants" v-for="(panelist, i) in townhall.panelists" :key="i">
                                        <div>
                                            <figure class="image is-128x128 is-inline-block">
                                                <img class="is-square" :src="panelist.image" />
                                            </figure>
                                        </div>
                                        <div>
                                            <span class="has-text-weight-bold" v-text="panelist.name"></span>
                                            <div v-for="(role, i) in panelist.roles" :key="i" style="margin-bottom: 10px">
                                                <span v-html="role.title"></span>
                                                <span class="is-italic" v-text="role.institution"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" v-if="workshop.live_schedule != None && workshop.live == false">
                <div class="container">
                    <SectionOne id="Schedule" />
                    <h1 class="title" id="schedule"><b>Workshop Schedule</b></h1>
                    <vue-markdown v-if="workshop.schedule_info != None">
                        {{ workshop.schedule_info }}
                    </vue-markdown>
                    <div class="card">
                        <div class="card-content">
                            <iframe :src="workshop.live_schedule" style="width: 100%; height: 1100px; border: 0"></iframe>
                            <br />
                            <a :href="workshop.schedule_download" style="width: 100%; height: 600px; border: 0">Download a Live Schedule PDF version</a>
                            <br />
                            <p v-if="workshop.static_schedule != None">
                                If you are having issues seeing the most recent version of the schedule (updated live every 5 minutes), feel free to download
                                the latest static version.
                                <a style="color: blue" :href="workshop.static_schedule" download>Download Static Schedule</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" v-if="workshop.presentations">
                <div class="container" v-if="workshop.pres_slides_link">
                    <SectionOne id="Presentation_Slides" />
                    <h1 class="title">Presentation Slides</h1>
                    <div v-if="workshop.pres_slides_link">
                        <div class="card">
                            <div class="card-content"><p>PDF Version of all Presentation Slides</p></div>
                            <div class="card-content"><iframe :src="workshop.pres_slides_link" style="width: 100%; height: 400px; border: 0"></iframe></div>
                        </div>
                    </div>
                    <div v-for="(presid, i) in workshop.presentations" :key="i">
                        <div v-if="presid.breakout">
                            <h1 class="subtitle">
                                <b>{{ presid.name }}</b>
                            </h1>
                            <div v-for="(breakout_id, i) in presid.breakout" :key="i">
                                <p>
                                    <span
                                        ><a style="color: blue" :href="breakout_id.url"> {{ breakout_id.name }} </a></span
                                    >
                                </p>
                                <p>{{ breakout_id.speaker }}</p>
                                <br />
                            </div>
                        </div>
                        <div v-else>
                            <h1 class="subtitle">{{ presid.name }}</h1>
                            <p>
                                <span><a style="color: blue" :href="presid.ppt"> Powerpoints </a></span>
                            </p>
                            <p>
                                <span><a style="color: blue" :href="presid.morning_url"> Morning Presentations Videos </a></span>
                            </p>
                            <p>
                                <span><a style="color: blue" :href="presid.afternoon_url"> Afternoon Presentations Videos </a></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" v-if="workshop.pre_recorded_talks">
                <div class="container">
                    <h1 class="title"><b>Pre-Recorded Talks</b></h1>
                    <p>
                        Pre-Recorded talks are accessible through our YouTube Channel and can be viewed at any time. We encourage you to go check out some of
                        the great talks submitted this year.
                    </p>
                    <br />
                    <div class="card">
                        <div class="card-content">
                            <iframe
                                :src="workshop.pre_recorded_talks"
                                :height="workshop.pre_recorded_talks_height || 1580"
                                style="width: 100%; border: 0"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>

            <div class="section" v-if="workshop.attendee_info != None">
                <div class="container">
                    <div>
                        <SectionOne id="WorkshopLogistics" />
                        <h2 class="title">Workshop Logistics</h2>
                        <div v-if="workshop.attendee_info.guide != None">
                            <p v-if="workshop.attendee_info.guide != None" style="padding-bottom: 0.5em">
                                <center>
                                    <a :href="workshop.attendee_info.guide" download style="width: 100%; height: 600px; border: 0"
                                        >Click to download the {{ workshop.name }} Attendee Guide</a
                                    >
                                </center>
                            </p>
                        </div>
                        <div v-if="workshop.attendee_info.general != None">
                            <div v-for="(wai, i) in workshop.attendee_info.general" :key="i">
                                <vue-markdown v-if="wai.p">{{ wai.p }}</vue-markdown>
                                <div v-if="wai.table">
                                    <button class="button is-small is-info" v-if="!table_show" v-on:click="open_table" type="button">Click for Details</button>
                                    <button class="button is-small is-danger" v-if="table_show" v-on:click="close_table" type="button">Close Details</button>
                                    <div class="card" v-if="table_show">
                                        <div class="card-content">
                                            <vue-markdown>{{ wai.table }}</vue-markdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="workshop.attendee_info.attendee != None">
                            <br />
                            <div class="columns">
                                <div class="left-half" id="large-screen-column">
                                    <div class="column">
                                        <div v-for="(wai, i) in workshop.attendee_info.attendee" :key="i">
                                            <SectionOne id="AttendeeInstructions" />
                                            <h3 class="subtitle">
                                                <b>Attendee Instructions</b>
                                            </h3>
                                            <vue-markdown>{{ wai.p }}</vue-markdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-half" id="large-screen-column">
                                    <div class="column">
                                        <div v-for="(wai, i) in workshop.attendee_info.presenter" :key="i">
                                            <SectionOne id="PresenterInstructions" />
                                            <h3 class="subtitle">
                                                <b>Presenter Instructions</b>
                                            </h3>
                                            <vue-markdown>{{ wai.p }}</vue-markdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="workshop.attendee_info.faqs != None">
                            <br /><br />
                            <SectionOne id="FAQs" />
                            <h3 class="subtitle"><b>FAQ's:</b></h3>
                            <div class="card" v-for="(faq, i) in workshop.attendee_info.faqs" :key="i">
                                <div class="card-content">
                                    <b>{{ faq.Q }}</b>
                                    <br />
                                    <vue-markdown :html="true">{{ faq.A }}</vue-markdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="workshop.attendee_info.hotel != None" style="margin-top: 0%">
                        <SectionOne id="Hotels" />
                        <h1 class="subtitle"><b>Hotel</b></h1>
                        <vue-markdown>{{ workshop.attendee_info.hotel }}</vue-markdown>
                    </div>
                    <div v-if="workshop.attendee_info.abstracts != None">
                        <br />
                        <SectionOne id="Abstracts" />
                        <h1 class="title"><b>Call for Abstracts!</b></h1>
                        <div v-if="workshop.sel_abstracts">
                            <div class="card" style="width: 40%">
                                <div class="card-content">
                                    <span class="icon is-small">
                                        <i class="fas fa-exclamation-triangle" style="color: orange"></i>
                                    </span>
                                    <b> Abstract Submission Deadline has passed. </b>
                                </div>
                            </div>
                        </div>
                        <div v-for="(desid, i) in workshop.attendee_info.abstracts" :key="i" class="workshop-abstracts">
                            <vue-markdown>{{ desid }}</vue-markdown>
                        </div>
                        <p v-if="!workshop.sel_abstracts">
                            To submit your abstract contact: <a href="mailto:abstracts@flightsoftware.org">abstracts@flightsoftware.org</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="section" v-if="workshop.code_of_conduct != None">
                <div class="container">
                    <SectionOne id="COC" />
                    <h2 class="title">Flight Software Workshop Code of Conduct</h2>
                    <div v-for="(coc, i) in workshop.code_of_conduct" :key="i">
                        <vue-markdown>{{ coc.p }}</vue-markdown>
                        <button class="button is-small is-info" v-if="!coc_show && coc.link" v-on:click="open_coc" type="button">Expand Code of Conduct</button>
                        <button class="button is-small is-danger" v-if="coc_show && coc.link" v-on:click="close_coc" type="button">
                            Close Code of Conduct
                        </button>
                        <iframe v-if="coc.link && coc_show" :src="coc.link" style="width: 100%; height: 1000px; border: 0"></iframe>
                    </div>
                </div>
            </div>
            <div class="section" v-if="workshop.committee">
                <div class="container">
                    <SectionOne id="Coordinators" />
                    <h1 class="title">Organizing Committee:</h1>
                    <div class="columns is-multiline">
                        <div class="column is-3" v-for="(coord, i) in workshop.committee" :key="i">
                            <div>
                                <b>{{ coord.position }}</b
                                ><br />
                                {{ coord.name }}<br />
                                {{ coord.institution }}
                            </div>
                        </div>
                    </div>
                    <p>
                        For any questions please contact:
                        <a href="mailto:info@flightsoftware.org?">info@flightsoftware.org</a>
                    </p>
                </div>
            </div>
            <div class="section" v-if="workshop.sponsors">
                <div class="container">
                    <SectionOne id="Sponsors" />
                    <h1 class="title">This Year's Sponsors:</h1>
                    <div align="center">
                        <div class="columns is-multiline" v-if="workshop.latest != true && workshop.public">
                            <div class="column is-2" v-for="(ws_spon, i) in workshop.sponsors" :key="i">
                                <img :src="ws_spon" style="max-height: 45px" />
                            </div>
                        </div>
                    </div>
                    <div align="center">
                        <div class="columns is-multiline" v-if="workshop.latest">
                            <div class="column is-2" v-for="(curr_sponsor, i) in curr_sponsors" :key="i">
                                <a :href="curr_sponsor.link">
                                    <img :src="curr_sponsor.hero" :alt="curr_sponsor.name" style="max-height: 45px" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="minigame-no-phone">
                <button class="button is-small is-info" v-if="!minigame_show" v-on:click="open_mini" type="button">Click Here to Follow the Spacecraft</button>
                <button class="button is-small is-danger" v-if="minigame_show" v-on:click="close_mini" type="button">Close Follow the Spacecraft</button>
            </div>
            <div class="card" v-if="workshop.minigame || minigame_show" id="minigame-no-phone">
                <div class="card-content">
                    <div class="container">
                        <h1 class="title">Follow the Spacecraft!</h1>
                        <p>While we get ready for the Workshop enjoy this mini-game!</p>
                        <p>Your objective... Follow the spacecraft with your mouse, wherever it goes.</p>
                        <br /><br /><br />
                        <div class="container-bkg">
                            <div
                                class="container"
                                style="width: 1000px; height: 500px; background-size: 350px 150px"
                                :style="{ backgroundImage: 'url(' + require('@/assets/minigame_bkg.png') + ')' }"
                            >
                                <div class="sc" :style="{ backgroundImage: 'url(' + require('@/assets/sc.png') + ')' }"></div>
                                <div class="counter"></div>
                            </div>
                        </div>
                        <br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    </v-content>
</template>

<script>
import sponsors from "@/sponsors.js";
import { CardModal } from "vue-bulma-modal";
import _ from "lodash";
import VueMarkdown from "vue-markdown";

export default {
    props: {
        workshop: {
            type: Object,
        },
    },
    components: {
        VueMarkdown,
        CardModal,
    },
    data() {
        return {
            curr_sponsors: _.sortBy(_.filter(sponsors, "current")),
            spanVisible: false,
            minigame_show: false,
            instructon_att_show: false,
            instructon_pres_show: false,
            table_show: false,
            coc_show: false,
        };
    },
    methods: {
        set_keynote(kn) {
            this.spanVisible = kn;
        },
        close() {
            this.spanVisible = false;
        },
        open_table() {
            this.table_show = true;
        },
        close_table() {
            this.table_show = false;
        },
        open_coc() {
            this.coc_show = true;
        },
        close_coc() {
            this.coc_show = false;
        },
        open_att_instructions() {
            this.instructon_att_show = true;
        },
        close_att_instructions() {
            this.instructon_att_show = false;
        },
        open_pres_instructions() {
            this.instructon_pres_show = true;
        },
        close_pres_instructions() {
            this.instructon_pres_show = false;
        },
        open_mini() {
            this.minigame_show = true;
        },
        close_mini() {
            this.minigame_show = false;
        },
        format_workshop_id(id) {
            return `${id.slice(0, 3)}-${id.slice(3)}`;
        },
    },
};
</script>

<style lang="scss">
.workshop-description p,
.workshop-abstracts p {
    margin: 1em 0;
}

.workshop-description ul {
    list-style: disc;
}

.workshop-description ul,
.workshop-abstracts ol {
    padding-left: 2.5em;
}
</style>

<style lang="scss" scoped>
.section {
    padding: 1rem 1.5rem 0.5rem 1.5rem;
}

a {
    color: blue;
}

.css-hero {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 50vh;
}

.styled-causeway {
    min-height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    // backgroundPosition: `0 ${-20 + this.state.scrollOffset / 20}px`,
}

@keyframes count {
    @for $i from 0 through 100 {
        #{ $i }% {
            content: "#{ $i }%";
        }
    }
}

@keyframes move {
    0% {
        top: 30%;
        left: 50%;
        transform: translate(-40%, -50%) rotate(10deg);
    }
    10% {
        top: 100%;
        left: 40%;
        transform: translate(-50%, -50%) rotate(-20deg);
    }
    20% {
        top: 80%;
        left: 20%;
        transform: translate(-50%, -50%) rotate(6deg);
    }
    25% {
        top: 80%;
        left: 20%;
        transform: translate(-50%, -50%) scale(0.3) rotate(-9deg);
    }
    37% {
        top: 80%;
        left: 20%;
        transform: translate(-50%, -50%) rotate(-14deg);
    }
    50% {
        top: 10%;
        left: 80%;
        transform: translate(-50%, -50%) scale(0.85) rotate(10deg);
    }
    60% {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.75) rotate(-8deg);
    }
    70% {
        top: 80%;
        left: 80%;
        transform: translate(-50%, -50%) scale(0.8) rotate(16deg);
    }
    83% {
        top: 40%;
        left: 80%;
        transform: translate(-50%, -50%) scale(0.7) rotate(12deg);
    }
    95% {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5) rotate(-9deg);
    }
    97% {
        top: 30%;
        left: 60%;
        transform: translate(-50%, -50%) scale(2) rotate(9deg);
    }
    100% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1) rotate(10deg);
    }
}
body {
    width: 100%;
    height: 100vh;
}

.sc {
    position: absolute;
    width: 108px;
    height: 64px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &:hover {
        animation: move 40s linear;
    }

    &:hover + .counter::after {
        animation: count 40s linear;
    }
}

.counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    &::after {
        content: "Follow Me!";
    }
}

.PageSections {
    padding-top: 0px;
    position: fixed;
    top: 10%;
    right: 1%;
    width: 9%;
    font-size: 12px;
    z-index: 1000;
}

card-modal {
    visibility: hidden;
}

footer {
    visibility: visible;
}

.left {
    width: 65%;
}

.right {
    width: 35%;
    margin-left: 0%;
}

.left-small {
    margin-left: 1%;
    width: 25%;
}

.right-large {
    width: 75%;
    margin-left: 3%;
}

.left-half {
    width: 50%;
    margin-left: 0%;
}

.right-half {
    width: 47%;
    margin-left: 0%;
}

.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blink {
    animation: blink 4s steps(5, start) infinite;
    -webkit-animation: blink 2s steps(5, start) infinite;
}

#townhalls span {
    display: block;
}

.townhall-participants {
    display: flex;
}

@keyframes blink {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
}

@media only screen and (max-width: 600px) {
    #large-screen-column {
        width: 100%;
    }
}
@media only screen and (max-width: 1440px) {
    #minigame-no-phone {
        display: none;
    }
    .PageSections {
        display: none;
    }
}

@media only screen and (min-width: 1440px) {
    .PageSections div.card-content {
        padding: 1em;
    }
}
</style>
